var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"eopoi-app eopoi-widget"},[_c('v-app',{attrs:{"id":_vm.id}},[_c('v-main',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[(_vm.isLoading)?_c('v-alert',{staticClass:"card_gradient",style:({
              color: _vm.textColor || 'white',
              ..._vm.cardGradientStyle
            }),attrs:{"dark":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":70,"width":8}}),_vm._v(" Lade... ")],1):_vm._e()],1)],1),(_vm.checkSeason('summer'))?_c('v-row',{staticClass:"pa-sm-10 pa-5"},[(
            _vm.mappedPois &&
              _vm.mappedPois['Touren & Wege'] &&
              _vm.checkMappingField('Touren & Wege') &&
              Object.keys(_vm.mappedPois['Touren & Wege']).length
          )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"card_gradient",style:({
              color: _vm.textColor || 'white',
              ..._vm.cardGradientStyle
            }),attrs:{"elevation":"4"},on:{"click":function($event){return _vm.linkToList('Touren & Wege')}}},[_c('v-icon',{staticClass:"bg_icon left",attrs:{"size":"50px"}},[_vm._v("$boot")]),_c('v-icon',{staticClass:"bg_icon right",attrs:{"size":"50px"}},[_vm._v("$mountainBiking")]),_c('v-card-title',{staticClass:"w-100 justify-center font-weight-bold",style:({ color: _vm.textColor || 'white' })},[_vm._v(" Touren & Wege ")]),_c('v-card-text',{staticClass:"w-100",style:({ color: _vm.textColor || 'white' })},[_vm._l((Object.keys(_vm.mappedPois['Touren & Wege'])),function(key){return _c('div',{key:key,staticClass:"card_content__wrapper"},[_c('span',{staticClass:"card_content__key"},[_vm._v(_vm._s(key))]),(_vm.mappedPois['Touren & Wege'][key].all)?_c('span',{staticClass:"card_content__count count_walking"},[_vm._v(" "+_vm._s(_vm.mappedPois["Touren & Wege"][key].all)+" ")]):_vm._e()])}),_c('br'),_c('div',{staticClass:"card_content__wrapper"},[_c('span',{staticClass:"card_content__key"},[_vm._v(" Aktuell nicht begehbar")]),(_vm.notOpenSum >= 1)?_c('span',{staticClass:"card_content__count count_all font-weight-bold"},[_vm._v(" "+_vm._s(_vm.notOpenSum)+" ")]):_c('span',{staticClass:"cars_content__count count_all"},[_vm._v(" 0 ")])]),_c('div',{staticClass:"w-100 justify-center d-flex pt-5"},[_c('v-btn',{staticClass:"bg-white color-main-blue button-opacity",attrs:{"icon":"","elevation":"1","small":""},on:{"click":function($event){return _vm.linkToList('Touren & Wege')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],2)],1)],1):_vm._e(),(
            _vm.mappedPois &&
              _vm.mappedPois.Badeseen &&
              _vm.checkMappingField('Badeseen') &&
              Object.keys(_vm.mappedPois.Badeseen).length
          )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"card_gradient",style:({
              color: _vm.textColor || 'white',
              ..._vm.cardGradientStyle
            }),attrs:{"elevation":"4"},on:{"click":function($event){return _vm.linkToList('Badeseen')}}},[_c('v-icon',{staticClass:"bg_icon left",attrs:{"size":"50px"}},[_vm._v("$fins")]),_c('v-icon',{staticClass:"bg_icon right",attrs:{"size":"50px"}},[_vm._v("$swimming")]),_c('v-card-title',{staticClass:"w-100 justify-center font-weight-bold",style:({ color: _vm.textColor || 'white' })},[_vm._v(" Badeseen ")]),_c('v-card-text',{staticClass:"w-100",style:({ color: _vm.textColor || 'white' })},[_c('span',{staticClass:"w-100 d-flex justify-center now-open-wording"},[_vm._v("Jetzt geöffnet")]),_vm._l((Object.keys(_vm.mappedPois.Badeseen)),function(key){return _c('div',{key:key,staticClass:"card_content__wrapper"},[_c('span',{staticClass:"card_content__key"},[_vm._v(_vm._s(key))]),(_vm.mappedPois.Badeseen[key].open)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" "+_vm._s(_vm.mappedPois.Badeseen[key].open)+"/ ")]):_vm._e(),(_vm.mappedPois.Badeseen[key].open === 0)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" 0/ ")]):_vm._e(),(_vm.mappedPois.Badeseen[key].all)?_c('span',{staticClass:"card_content__count count_all"},[_vm._v(" "+_vm._s(_vm.mappedPois.Badeseen[key].all)+" ")]):_vm._e()])}),_c('div',{staticClass:"w-100 justify-center d-flex pt-5"},[_c('v-btn',{staticClass:"bg-white color-main-blue button-opacity",attrs:{"icon":"","elevation":"1","small":""},on:{"click":function($event){return _vm.linkToList('Badeseen')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],2)],1)],1):_vm._e(),(
            _vm.mappedPois &&
              _vm.mappedPois.Bergbahnen &&
              _vm.checkMappingField('Bergbahnen') &&
              Object.keys(_vm.mappedPois.Bergbahnen).length
          )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"card_gradient",style:({
              color: _vm.textColor || 'white',
              ..._vm.cardGradientStyle
            }),attrs:{"elevation":"4"},on:{"click":function($event){return _vm.linkToList('Bergbahnen')}}},[_c('v-icon',{staticClass:"bg_icon left",attrs:{"size":"50px"}},[_vm._v("$cableWay")]),_c('v-icon',{staticClass:"bg_icon right",attrs:{"size":"50px"}},[_vm._v("$cableWayPeople")]),_c('v-card-title',{staticClass:"w-100 justify-center font-weight-bold",style:({ color: _vm.textColor || 'white' })},[_vm._v(" Bergbahnen ")]),_c('v-card-text',{staticClass:"w-100",style:({ color: _vm.textColor || 'white' })},[_c('div',[_c('span',{staticClass:"w-100 d-flex justify-center now-open-wording"},[_vm._v("Jetzt geöffnet")])]),_vm._l((Object.keys(_vm.mappedPois.Bergbahnen)),function(key){return _c('div',{key:key,staticClass:"card_content__wrapper"},[_c('span',{staticClass:"card_content__key"},[_vm._v(" "+_vm._s(key)+" ")]),(_vm.mappedPois.Bergbahnen[key].open)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" "+_vm._s(_vm.mappedPois.Bergbahnen[key].open)+"/ ")]):_vm._e(),(_vm.mappedPois.Bergbahnen[key].open === 0)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" 0/ ")]):_vm._e(),(_vm.mappedPois.Bergbahnen[key].all)?_c('span',{staticClass:"card_content__count count_all"},[_vm._v(" "+_vm._s(_vm.mappedPois.Bergbahnen[key].all)+" ")]):_vm._e()])}),_c('div',{staticClass:"w-100 justify-center d-flex pt-5"},[_c('v-btn',{staticClass:"bg-white color-main-blue button-opacity",attrs:{"icon":"","elevation":"1","small":""},on:{"click":function($event){return _vm.linkToList('Bergbahnen')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],2)],1)],1):_vm._e(),(
            _vm.mappedPois &&
              _vm.mappedPois['Hütten & Alpen'] &&
              _vm.checkMappingField('Hütten & Alpen') &&
              Object.keys(_vm.mappedPois['Hütten & Alpen']).length
          )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"card_gradient",style:({
              color: _vm.textColor || 'white',
              ..._vm.cardGradientStyle
            }),attrs:{"elevation":"4"},on:{"click":function($event){return _vm.linkToList('Hütten & Alpen')}}},[_c('v-icon',{staticClass:"bg_icon left",attrs:{"size":"50px"}},[_vm._v("$hut")]),_c('v-icon',{staticClass:"bg_icon right",attrs:{"size":"50px"}},[_vm._v("$hutAlt")]),_c('v-card-title',{staticClass:"w-100 justify-center font-weight-bold",style:({ color: _vm.textColor || 'white' })},[_vm._v(" Hütten & Alpen ")]),_c('v-card-text',{staticClass:"w-100",style:({ color: _vm.textColor || 'white' })},[_c('div',[_c('span',{staticClass:"w-100 d-flex justify-center now-open-wording"},[_vm._v("Jetzt geöffnet")])]),_vm._l((Object.keys(_vm.mappedPois['Hütten & Alpen'])),function(key){return _c('div',{key:key,staticClass:"card_content__wrapper"},[_c('span',{staticClass:"card_content__key"},[_vm._v(_vm._s(key))]),(_vm.mappedPois['Hütten & Alpen'][key].open)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" "+_vm._s(_vm.mappedPois["Hütten & Alpen"][key].open)+"/ ")]):_vm._e(),(_vm.mappedPois['Hütten & Alpen'][key].open === 0)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" 0/ ")]):_vm._e(),(_vm.mappedPois['Hütten & Alpen'][key].all)?_c('span',{staticClass:"card_content__count count_all"},[_vm._v(" "+_vm._s(_vm.mappedPois["Hütten & Alpen"][key].all)+" ")]):_vm._e()])}),_c('div',{staticClass:"w-100 justify-center d-flex pt-5"},[_c('v-btn',{staticClass:"bg-white color-main-blue button-opacity",attrs:{"icon":"","elevation":"1","small":""},on:{"click":function($event){return _vm.linkToList('Hütten & Alpen')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],2)],1)],1):_vm._e(),(
            _vm.mappedPois &&
              _vm.mappedPois['Freizeit & Aktivität'] &&
              _vm.checkMappingField('Freizeit & Aktivität') &&
              Object.keys(_vm.mappedPois['Freizeit & Aktivität']).length
          )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"card_gradient",style:({
              color: _vm.textColor || 'white',
              ..._vm.cardGradientStyle
            }),attrs:{"elevation":"4"},on:{"click":function($event){return _vm.linkToList('Freizeit & Aktivität')}}},[_c('v-icon',{staticClass:"bg_icon left",attrs:{"size":"50px"}},[_vm._v("$kayaking")]),_c('v-icon',{staticClass:"bg_icon right",attrs:{"size":"50px"}},[_vm._v("$pinFavorite")]),_c('v-card-title',{staticClass:"w-100 justify-center font-weight-bold",style:({ color: _vm.textColor || 'white' })},[_vm._v(" Freizeit & Aktivität ")]),_c('v-card-text',{staticClass:"w-100",style:({ color: _vm.textColor || 'white' })},[_c('div',[_c('span',{staticClass:"w-100 d-flex justify-center now-open-wording"},[_vm._v("Jetzt geöffnet")])]),_vm._l((Object.keys(_vm.mappedPois['Freizeit & Aktivität'])),function(key){return _c('div',{key:key,staticClass:"card_content__wrapper"},[_c('span',{staticClass:"card_content__key"},[_vm._v(_vm._s(key))]),(_vm.mappedPois['Freizeit & Aktivität'][key].open)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" "+_vm._s(_vm.mappedPois["Freizeit & Aktivität"][key].open)+"/ ")]):_vm._e(),(_vm.mappedPois['Freizeit & Aktivität'][key].open === 0)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" 0/ ")]):_vm._e(),(_vm.mappedPois['Freizeit & Aktivität'][key].all)?_c('span',{staticClass:"card_content__count count_all"},[_vm._v(" "+_vm._s(_vm.mappedPois["Freizeit & Aktivität"][key].all)+" ")]):_vm._e()])}),_c('div',{staticClass:"w-100 justify-center d-flex pt-5"},[_c('v-btn',{staticClass:"bg-white color-main-blue button-opacity",attrs:{"icon":"","elevation":"1","small":""},on:{"click":function($event){return _vm.linkToList('Freizeit & Aktivität')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],2)],1)],1):_vm._e(),(
            _vm.mappedPois &&
              _vm.mappedPois['Essen & Trinken'] &&
              _vm.checkMappingField('Essen & Trinken') &&
              Object.keys(_vm.mappedPois['Essen & Trinken']).length
          )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"card_gradient",style:({
              color: _vm.textColor || 'white',
              ..._vm.cardGradientStyle
            }),attrs:{"elevation":"4"},on:{"click":function($event){return _vm.linkToList('Essen & Trinken')}}},[_c('v-icon',{staticClass:"bg_icon left",attrs:{"size":"50px"}},[_vm._v("$pinFavorite")]),_c('v-card-title',{staticClass:"w-100 justify-center font-weight-bold",style:({ color: _vm.textColor || 'white' })},[_vm._v(" Essen & Trinken ")]),_c('v-card-text',{staticClass:"w-100",style:({ color: _vm.textColor || 'white' })},[_vm._l((Object.keys(_vm.mappedPois['Essen & Trinken'])),function(key){return _c('div',{key:key,staticClass:"card_content__wrapper"},[_c('span',{staticClass:"card_content__key"},[_vm._v(_vm._s(key))]),(_vm.mappedPois['Essen & Trinken'][key].open)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" "+_vm._s(_vm.mappedPois["Essen & Trinken"][key].open)+"/ ")]):_vm._e(),(_vm.mappedPois['Essen & Trinken'][key].open === 0)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" 0/ ")]):_vm._e(),(_vm.mappedPois['Essen & Trinken'][key].all)?_c('span',{staticClass:"card_content__count count_all"},[_vm._v(" "+_vm._s(_vm.mappedPois["Essen & Trinken"][key].all)+" ")]):_vm._e()])}),_c('div',{staticClass:"w-100 justify-center d-flex pt-5"},[_c('v-btn',{staticClass:"bg-white color-main-blue button-opacity",attrs:{"icon":"","elevation":"1","small":""},on:{"click":function($event){return _vm.linkToList('Essen & Trinken')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],2)],1)],1):_vm._e(),(
            _vm.mappedPois &&
              _vm.mappedPois['Service'] &&
              _vm.checkMappingField('Service') &&
              Object.keys(_vm.mappedPois['Service']).length
          )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"card_gradient",style:({
              color: _vm.textColor || 'white',
              ..._vm.cardGradientStyle
            }),attrs:{"elevation":"4"},on:{"click":function($event){return _vm.linkToList('Service')}}},[_c('v-icon',{staticClass:"bg_icon left",attrs:{"size":"50px"}},[_vm._v("$pinFavorite")]),_c('v-card-title',{staticClass:"w-100 justify-center font-weight-bold",style:({ color: _vm.textColor || 'white' })},[_vm._v(" Service ")]),_c('v-card-text',{staticClass:"w-100",style:({ color: _vm.textColor || 'white' })},[_vm._l((Object.keys(_vm.mappedPois['Service'])),function(key){return _c('div',{key:key,staticClass:"card_content__wrapper"},[_c('span',{staticClass:"card_content__key"},[_vm._v(_vm._s(key))]),(_vm.mappedPois['Service'][key].open)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" "+_vm._s(_vm.mappedPois["Service"][key].open)+"/ ")]):_vm._e(),(_vm.mappedPois['Service'][key].open === 0)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" 0/ ")]):_vm._e(),(_vm.mappedPois['Service'][key].all)?_c('span',{staticClass:"card_content__count count_all"},[_vm._v(" "+_vm._s(_vm.mappedPois["Service"][key].all)+" ")]):_vm._e()])}),_c('div',{staticClass:"w-100 justify-center d-flex pt-5"},[_c('v-btn',{staticClass:"bg-white color-main-blue button-opacity",attrs:{"icon":"","elevation":"1","small":""},on:{"click":function($event){return _vm.linkToList('Service')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],2)],1)],1):_vm._e(),(
            _vm.mappedPois &&
              _vm.mappedPois['Gesundheit'] &&
              _vm.checkMappingField('Gesundheit') &&
              Object.keys(_vm.mappedPois['Gesundheit']).length
          )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"card_gradient",style:({
              color: _vm.textColor || 'white',
              ..._vm.cardGradientStyle
            }),attrs:{"elevation":"4"},on:{"click":function($event){return _vm.linkToList('Gesundheit')}}},[_c('v-icon',{staticClass:"bg_icon left",attrs:{"size":"50px"}},[_vm._v("$pinFavorite")]),_c('v-card-title',{staticClass:"w-100 justify-center font-weight-bold",style:({ color: _vm.textColor || 'white' })},[_vm._v(" Gesundheit ")]),_c('v-card-text',{staticClass:"w-100",style:({ color: _vm.textColor || 'white' })},[_vm._l((Object.keys(_vm.mappedPois['Gesundheit'])),function(key){return _c('div',{key:key,staticClass:"card_content__wrapper"},[_c('span',{staticClass:"card_content__key"},[_vm._v(_vm._s(key))]),(_vm.mappedPois['Gesundheit'][key].open)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" "+_vm._s(_vm.mappedPois["Gesundheit"][key].open)+"/ ")]):_vm._e(),(_vm.mappedPois['Gesundheit'][key].open === 0)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" 0/ ")]):_vm._e(),(_vm.mappedPois['Gesundheit'][key].all)?_c('span',{staticClass:"card_content__count count_all"},[_vm._v(" "+_vm._s(_vm.mappedPois["Gesundheit"][key].all)+" ")]):_vm._e()])}),_c('div',{staticClass:"w-100 justify-center d-flex pt-5"},[_c('v-btn',{staticClass:"bg-white color-main-blue button-opacity",attrs:{"icon":"","elevation":"1","small":""},on:{"click":function($event){return _vm.linkToList('Gesundheit')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],2)],1)],1):_vm._e(),(
            _vm.mappedPois &&
              _vm.mappedPois['Einkaufen'] &&
              _vm.checkMappingField('Einkaufen') &&
              Object.keys(_vm.mappedPois['Einkaufen']).length
          )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"card_gradient",style:({
              color: _vm.textColor || 'white',
              ..._vm.cardGradientStyle
            }),attrs:{"elevation":"4"},on:{"click":function($event){return _vm.linkToList('Einkaufen')}}},[_c('v-icon',{staticClass:"bg_icon left",attrs:{"size":"50px"}},[_vm._v("$pinFavorite")]),_c('v-card-title',{staticClass:"w-100 justify-center font-weight-bold",style:({ color: _vm.textColor || 'white' })},[_vm._v(" Einkaufen ")]),_c('v-card-text',{staticClass:"w-100",style:({ color: _vm.textColor || 'white' })},[_vm._l((Object.keys(_vm.mappedPois['Einkaufen'])),function(key){return _c('div',{key:key,staticClass:"card_content__wrapper"},[_c('span',{staticClass:"card_content__key"},[_vm._v(_vm._s(key))]),(_vm.mappedPois['Einkaufen'][key].open)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" "+_vm._s(_vm.mappedPois["Einkaufen"][key].open)+"/ ")]):_vm._e(),(_vm.mappedPois['Einkaufen'][key].open === 0)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" 0/ ")]):_vm._e(),(_vm.mappedPois['Einkaufen'][key].all)?_c('span',{staticClass:"card_content__count count_all"},[_vm._v(" "+_vm._s(_vm.mappedPois["Einkaufen"][key].all)+" ")]):_vm._e()])}),_c('div',{staticClass:"w-100 justify-center d-flex pt-5"},[_c('v-btn',{staticClass:"bg-white color-main-blue button-opacity",attrs:{"icon":"","elevation":"1","small":""},on:{"click":function($event){return _vm.linkToList('Einkaufen')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],2)],1)],1):_vm._e()],1):_vm._e(),(_vm.checkSeason('winter'))?_c('v-row',{staticClass:"pa-sm-10 pa-5"},[(
            _vm.mappedPois &&
              _vm.mappedPois['Bergbahnen & Skifahren'] &&
              _vm.checkMappingField('Bergbahnen & Skifahren') &&
              Object.keys(_vm.mappedPois['Bergbahnen & Skifahren']).length
          )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"card_gradient",style:({
              color: _vm.textColor || 'white',
              ..._vm.cardGradientStyle
            }),attrs:{"elevation":"4"},on:{"click":function($event){return _vm.linkToList('Bergbahnen & Skifahren')}}},[_c('v-icon',{staticClass:"bg_icon left",attrs:{"size":"50px"}},[_vm._v("$slope")]),_c('v-icon',{staticClass:"bg_icon right",attrs:{"size":"50px"}},[_vm._v("$skiingPeople")]),_c('v-card-title',{staticClass:"w-100 justify-center font-weight-bold",style:({ color: _vm.textColor || 'white' })},[_vm._v(" Bergbahnen & Skifahren ")]),_c('v-card-text',{staticClass:"w-100",style:({ color: _vm.textColor || 'white' })},[_c('div',[_c('span',{staticClass:"w-100 d-flex justify-center now-open-wording"},[_vm._v("Jetzt geöffnet")])]),_vm._l((Object.keys(
                  _vm.mappedPois['Bergbahnen & Skifahren']
                )),function(key){return _c('div',{key:key,staticClass:"card_content__wrapper"},[_c('span',{staticClass:"card_content__key"},[_vm._v(" "+_vm._s(key)+" ")]),(_vm.mappedPois['Bergbahnen & Skifahren'][key].open)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" "+_vm._s(_vm.mappedPois["Bergbahnen & Skifahren"][key].open)+"/ ")]):_vm._e(),(_vm.mappedPois['Bergbahnen & Skifahren'][key].open === 0)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" 0/ ")]):_vm._e(),(_vm.mappedPois['Bergbahnen & Skifahren'][key].all)?_c('span',{staticClass:"card_content__count count_all"},[_vm._v(" "+_vm._s(_vm.mappedPois["Bergbahnen & Skifahren"][key].all)+" ")]):_vm._e()])}),_c('div',{staticClass:"w-100 justify-center d-flex pt-5"},[_c('v-btn',{staticClass:"bg-white color-main-blue button-opacity",attrs:{"icon":"","elevation":"1","small":""},on:{"click":function($event){return _vm.linkToList('Bergbahnen & Skifahren')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],2)],1)],1):_vm._e(),(
            _vm.mappedPois &&
              _vm.mappedPois.Langlaufen &&
              _vm.checkMappingField('Langlaufen') &&
              Object.keys(_vm.mappedPois.Langlaufen).length
          )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"card_gradient",style:({
              color: _vm.textColor || 'white',
              ..._vm.cardGradientStyle
            }),attrs:{"elevation":"4"},on:{"click":function($event){return _vm.linkToList('Langlaufen')}}},[_c('v-icon',{staticClass:"bg_icon left",attrs:{"size":"50px"}},[_vm._v("$crossCountrySkiing ")]),_c('v-card-title',{staticClass:"w-100 justify-center font-weight-bold",style:({ color: _vm.textColor || 'white' })},[_vm._v(" Langlaufen ")]),_c('v-card-text',{staticClass:"w-100",style:({ color: _vm.textColor || 'white' })},[_c('div',[_c('span',{staticClass:"w-100 d-flex justify-center now-open-wording"},[_vm._v("Jetzt geöffnet/gespurt")])]),_vm._l((Object.keys(_vm.mappedPois.Langlaufen)),function(key){return _c('div',{key:key,staticClass:"card_content__wrapper"},[_c('span',{staticClass:"card_content__key"},[_vm._v(" "+_vm._s(key)+" ")]),(_vm.mappedPois['Langlaufen'][key].open)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" "+_vm._s(_vm.mappedPois.Langlaufen[key].open)+"/ ")]):_vm._e(),(_vm.mappedPois['Langlaufen'][key].open === 0)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" 0/ ")]):_vm._e(),(_vm.mappedPois['Langlaufen'][key].all)?_c('span',{staticClass:"card_content__count count_all"},[_vm._v(" "+_vm._s(_vm.mappedPois.Langlaufen[key].all)+" ")]):_vm._e()])}),_c('div',{staticClass:"w-100 justify-center d-flex pt-5"},[_c('v-btn',{staticClass:"bg-white color-main-blue button-opacity",attrs:{"icon":"","elevation":"1","small":""},on:{"click":function($event){return _vm.linkToList('Langlaufen')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],2)],1)],1):_vm._e(),(
            _vm.mappedPois &&
              _vm.mappedPois['Hütten & Alpen'] &&
              _vm.checkMappingField('Hütten & Alpen') &&
              Object.keys(_vm.mappedPois['Hütten & Alpen']).length
          )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"card_gradient",style:({
              color: _vm.textColor || 'white',
              ..._vm.cardGradientStyle
            }),attrs:{"elevation":"4"},on:{"click":function($event){return _vm.linkToList('Hütten & Alpen')}}},[_c('v-icon',{staticClass:"bg_icon left",attrs:{"size":"50px"}},[_vm._v("$hut")]),_c('v-icon',{staticClass:"bg_icon right",attrs:{"size":"50px"}},[_vm._v("$hutAlt")]),_c('v-card-title',{staticClass:"w-100 justify-center font-weight-bold",style:({ color: _vm.textColor || 'white' })},[_vm._v(" Hütten & Alpen ")]),_c('v-card-text',{staticClass:"w-100",style:({ color: _vm.textColor || 'white' })},[_c('div',[_c('span',{staticClass:"w-100 d-flex justify-center now-open-wording"},[_vm._v("Jetzt geöffnet")])]),_vm._l((Object.keys(_vm.mappedPois['Hütten & Alpen'])),function(key){return _c('div',{key:key,staticClass:"card_content__wrapper"},[_c('span',{staticClass:"card_content__key"},[_vm._v(_vm._s(key))]),(_vm.mappedPois['Hütten & Alpen'][key].open)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" "+_vm._s(_vm.mappedPois["Hütten & Alpen"][key].open)+"/ ")]):_vm._e(),(_vm.mappedPois['Hütten & Alpen'][key].open === 0)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" 0/ ")]):_vm._e(),(_vm.mappedPois['Hütten & Alpen'][key].all)?_c('span',{staticClass:"card_content__count count_all"},[_vm._v(" "+_vm._s(_vm.mappedPois["Hütten & Alpen"][key].all)+" ")]):_vm._e()])}),_c('div',{staticClass:"w-100 justify-center d-flex pt-5"},[_c('v-btn',{staticClass:"bg-white color-main-blue button-opacity",attrs:{"icon":"","elevation":"1","small":""},on:{"click":function($event){return _vm.linkToList('Hütten & Alpen')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],2)],1)],1):_vm._e(),(
            _vm.mappedPois &&
              _vm.mappedPois.Winterwandern &&
              _vm.checkMappingField('Winterwandern') &&
              Object.keys(_vm.mappedPois.Winterwandern).length
          )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"card_gradient",style:({
              color: _vm.textColor || 'white',
              ..._vm.cardGradientStyle
            }),attrs:{"elevation":"4"},on:{"click":function($event){return _vm.linkToList('Winterwandern')}}},[_c('v-icon',{staticClass:"bg_icon left",attrs:{"size":"50px"}},[_vm._v("$snowShoes")]),_c('v-card-title',{staticClass:"w-100 justify-center font-weight-bold",style:({ color: _vm.textColor || 'white' })},[_vm._v(" Winterwandern ")]),_c('v-card-text',{staticClass:"w-100",style:({ color: _vm.textColor || 'white' })},[_vm._l((Object.keys(_vm.mappedPois.Winterwandern)),function(key){return _c('div',{key:key,staticClass:"card_content__wrapper"},[_c('span',{staticClass:"card_content__key"},[_vm._v(" "+_vm._s(key)+" ")]),(_vm.mappedPois['Winterwandern'][key].all)?_c('span',{staticClass:"card_content__count count_all font-weight-bold"},[_vm._v(" "+_vm._s(_vm.mappedPois["Winterwandern"][key].all)+" ")]):_vm._e()])}),_c('br'),_vm._l((Object.keys(_vm.mappedPois.Winterwandern)),function(key){return _c('div',{key:key,staticClass:"card_content__wrapper"},[(_vm.mappedPois['Winterwandern'][key].notOpen)?_c('span',{staticClass:"card_content__key"},[_vm._v(" Aktuell gesperrt / nicht begehbar ")]):_vm._e(),(_vm.mappedPois['Winterwandern'][key].notOpen)?_c('span',{staticClass:"card_content__count count_walking"},[_vm._v(" "+_vm._s(_vm.mappedPois["Winterwandern"][key].notOpen)+" ")]):_vm._e(),(_vm.mappedPois['Winterwandern'][key].closed === 0)?_c('span',{staticClass:"card_content__count count_all"},[_vm._v(" 0 ")]):_vm._e()])}),_c('div',{staticClass:"w-100 justify-center d-flex pt-5"},[_c('v-btn',{staticClass:"bg-white color-main-blue button-opacity",attrs:{"icon":"","elevation":"1","small":""},on:{"click":function($event){return _vm.linkToList('Winterwandern')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],2)],1)],1):_vm._e(),(
            _vm.mappedPois &&
              _vm.mappedPois.Rodeln &&
              _vm.checkMappingField('Rodeln') &&
              Object.keys(_vm.mappedPois.Rodeln).length
          )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"card_gradient",style:({
              color: _vm.textColor || 'white',
              ..._vm.cardGradientStyle
            }),attrs:{"elevation":"4"},on:{"click":function($event){return _vm.linkToList('Rodeln')}}},[_c('v-icon',{staticClass:"bg_icon left",attrs:{"size":"50px"}},[_vm._v("$sled")]),_c('v-card-title',{staticClass:"w-100 justify-center font-weight-bold",style:({ color: _vm.textColor || 'white' })},[_vm._v(" Rodeln ")]),_c('v-card-text',{staticClass:"w-100",style:({ color: _vm.textColor || 'white' })},[_c('div',[_c('span',{staticClass:"w-100 d-flex justify-center now-open-wording"},[_vm._v("Jetzt geöffnet")])]),_vm._l((Object.keys(_vm.mappedPois.Rodeln)),function(key){return _c('div',{key:key,staticClass:"card_content__wrapper"},[_c('span',{staticClass:"card_content__key"},[_vm._v(" "+_vm._s(key)+" ")]),(_vm.mappedPois['Rodeln'][key].open)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" "+_vm._s(_vm.mappedPois.Rodeln[key].open)+"/ ")]):_vm._e(),(_vm.mappedPois['Rodeln'][key].open === 0)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" 0/ ")]):_vm._e(),(_vm.mappedPois['Rodeln'][key].all)?_c('span',{staticClass:"card_content__count count_all"},[_vm._v(" "+_vm._s(_vm.mappedPois.Rodeln[key].all)+" ")]):_vm._e()])}),_c('div',{staticClass:"w-100 justify-center d-flex pt-5"},[_c('v-btn',{staticClass:"bg-white color-main-blue button-opacity",attrs:{"icon":"","elevation":"1","small":""},on:{"click":function($event){return _vm.linkToList('Rodeln')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],2)],1)],1):_vm._e(),(
            _vm.mappedPois &&
              _vm.mappedPois['Freizeit & Aktivität'] &&
              _vm.checkMappingField('Freizeit & Aktivität') &&
              Object.keys(_vm.mappedPois['Freizeit & Aktivität']).length
          )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"card_gradient",style:({
              color: _vm.textColor || 'white',
              ..._vm.cardGradientStyle
            }),attrs:{"elevation":"4"},on:{"click":function($event){return _vm.linkToList('Freizeit & Aktivität')}}},[_c('v-icon',{staticClass:"bg_icon left",attrs:{"size":"50px"}},[_vm._v("$iceSkate")]),_c('v-icon',{staticClass:"bg_icon right",attrs:{"size":"50px"}},[_vm._v("$pinFavorite")]),_c('v-card-title',{staticClass:"w-100 justify-center font-weight-bold",style:({ color: _vm.textColor || 'white' })},[_vm._v(" Freizeit & Aktivität ")]),_c('v-card-text',{staticClass:"w-100",style:({ color: _vm.textColor || 'white' })},[_c('div',[_c('span',{staticClass:"w-100 d-flex justify-center now-open-wording"},[_vm._v("Jetzt geöffnet")])]),_vm._l((Object.keys(_vm.mappedPois['Freizeit & Aktivität'])),function(key){return _c('div',{key:key,staticClass:"card_content__wrapper"},[_c('span',{staticClass:"card_content__key"},[_vm._v(_vm._s(key))]),(_vm.mappedPois['Freizeit & Aktivität'][key].open)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" "+_vm._s(_vm.mappedPois["Freizeit & Aktivität"][key].open)+"/ ")]):_vm._e(),(_vm.mappedPois['Freizeit & Aktivität'][key].open === 0)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" 0/ ")]):_vm._e(),(_vm.mappedPois['Freizeit & Aktivität'][key].all)?_c('span',{staticClass:"card_content__count count_all"},[_vm._v(" "+_vm._s(_vm.mappedPois["Freizeit & Aktivität"][key].all)+" ")]):_vm._e()])}),_c('div',{staticClass:"w-100 justify-center d-flex pt-5"},[_c('v-btn',{staticClass:"bg-white color-main-blue button-opacity",attrs:{"icon":"","elevation":"1","small":""},on:{"click":function($event){return _vm.linkToList('Freizeit & Aktivität')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],2)],1)],1):_vm._e(),(
            _vm.mappedPois &&
              _vm.mappedPois['Essen & Trinken'] &&
              _vm.checkMappingField('Essen & Trinken') &&
              Object.keys(_vm.mappedPois['Essen & Trinken']).length
          )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"card_gradient",style:({
              color: _vm.textColor || 'white',
              ..._vm.cardGradientStyle
            }),attrs:{"elevation":"4"},on:{"click":function($event){return _vm.linkToList('Essen & Trinken')}}},[_c('v-icon',{staticClass:"bg_icon left",attrs:{"size":"50px"}},[_vm._v("$pinFavorite")]),_c('v-card-title',{staticClass:"w-100 justify-center font-weight-bold",style:({ color: _vm.textColor || 'white' })},[_vm._v(" Essen & Trinken ")]),_c('v-card-text',{staticClass:"w-100",style:({ color: _vm.textColor || 'white' })},[_vm._l((Object.keys(_vm.mappedPois['Essen & Trinken'])),function(key){return _c('div',{key:key,staticClass:"card_content__wrapper"},[_c('span',{staticClass:"card_content__key"},[_vm._v(_vm._s(key))]),(_vm.mappedPois['Essen & Trinken'][key].open)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" "+_vm._s(_vm.mappedPois["Essen & Trinken"][key].open)+"/ ")]):_vm._e(),(_vm.mappedPois['Essen & Trinken'][key].open === 0)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" 0/ ")]):_vm._e(),(_vm.mappedPois['Essen & Trinken'][key].all)?_c('span',{staticClass:"card_content__count count_all"},[_vm._v(" "+_vm._s(_vm.mappedPois["Essen & Trinken"][key].all)+" ")]):_vm._e()])}),_c('div',{staticClass:"w-100 justify-center d-flex pt-5"},[_c('v-btn',{staticClass:"bg-white color-main-blue button-opacity",attrs:{"icon":"","elevation":"1","small":""},on:{"click":function($event){return _vm.linkToList('Essen & Trinken')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],2)],1)],1):_vm._e(),(
            _vm.mappedPois &&
              _vm.mappedPois['Service'] &&
              _vm.checkMappingField('Service') &&
              Object.keys(_vm.mappedPois['Service']).length
          )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"card_gradient",style:({
              color: _vm.textColor || 'white',
              ..._vm.cardGradientStyle
            }),attrs:{"elevation":"4"},on:{"click":function($event){return _vm.linkToList('Service')}}},[_c('v-icon',{staticClass:"bg_icon left",attrs:{"size":"50px"}},[_vm._v("$pinFavorite")]),_c('v-card-title',{staticClass:"w-100 justify-center font-weight-bold",style:({ color: _vm.textColor || 'white' })},[_vm._v(" Service ")]),_c('v-card-text',{staticClass:"w-100",style:({ color: _vm.textColor || 'white' })},[_vm._l((Object.keys(_vm.mappedPois['Service'])),function(key){return _c('div',{key:key,staticClass:"card_content__wrapper"},[_c('span',{staticClass:"card_content__key"},[_vm._v(_vm._s(key))]),(_vm.mappedPois['Service'][key].open)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" "+_vm._s(_vm.mappedPois["Service"][key].open)+"/ ")]):_vm._e(),(_vm.mappedPois['Service'][key].open === 0)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" 0/ ")]):_vm._e(),(_vm.mappedPois['Service'][key].all)?_c('span',{staticClass:"card_content__count count_all"},[_vm._v(" "+_vm._s(_vm.mappedPois["Service"][key].all)+" ")]):_vm._e()])}),_c('div',{staticClass:"w-100 justify-center d-flex pt-5"},[_c('v-btn',{staticClass:"bg-white color-main-blue button-opacity",attrs:{"icon":"","elevation":"1","small":""},on:{"click":function($event){return _vm.linkToList('Service')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],2)],1)],1):_vm._e(),(
            _vm.mappedPois &&
              _vm.mappedPois['Gesundheit'] &&
              _vm.checkMappingField('Gesundheit') &&
              Object.keys(_vm.mappedPois['Gesundheit']).length
          )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"card_gradient",style:({
              color: _vm.textColor || 'white',
              ..._vm.cardGradientStyle
            }),attrs:{"elevation":"4"},on:{"click":function($event){return _vm.linkToList('Gesundheit')}}},[_c('v-icon',{staticClass:"bg_icon left",attrs:{"size":"50px"}},[_vm._v("$pinFavorite")]),_c('v-card-title',{staticClass:"w-100 justify-center font-weight-bold",style:({ color: _vm.textColor || 'white' })},[_vm._v(" Gesundheit ")]),_c('v-card-text',{staticClass:"w-100",style:({ color: _vm.textColor || 'white' })},[_vm._l((Object.keys(_vm.mappedPois['Gesundheit'])),function(key){return _c('div',{key:key,staticClass:"card_content__wrapper"},[_c('span',{staticClass:"card_content__key"},[_vm._v(_vm._s(key))]),(_vm.mappedPois['Gesundheit'][key].open)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" "+_vm._s(_vm.mappedPois["Gesundheit"][key].open)+"/ ")]):_vm._e(),(_vm.mappedPois['Gesundheit'][key].open === 0)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" 0/ ")]):_vm._e(),(_vm.mappedPois['Gesundheit'][key].all)?_c('span',{staticClass:"card_content__count count_all"},[_vm._v(" "+_vm._s(_vm.mappedPois["Gesundheit"][key].all)+" ")]):_vm._e()])}),_c('div',{staticClass:"w-100 justify-center d-flex pt-5"},[_c('v-btn',{staticClass:"bg-white color-main-blue button-opacity",attrs:{"icon":"","elevation":"1","small":""},on:{"click":function($event){return _vm.linkToList('Gesundheit')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],2)],1)],1):_vm._e(),(
            _vm.mappedPois &&
              _vm.mappedPois['Einkaufen'] &&
              _vm.checkMappingField('Einkaufen') &&
              Object.keys(_vm.mappedPois['Einkaufen']).length
          )?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"card_gradient",style:({
              color: _vm.textColor || 'white',
              ..._vm.cardGradientStyle
            }),attrs:{"elevation":"4"},on:{"click":function($event){return _vm.linkToList('Einkaufen')}}},[_c('v-icon',{staticClass:"bg_icon left",attrs:{"size":"50px"}},[_vm._v("$pinFavorite")]),_c('v-card-title',{staticClass:"w-100 justify-center font-weight-bold",style:({ color: _vm.textColor || 'white' })},[_vm._v(" Einkaufen ")]),_c('v-card-text',{staticClass:"w-100",style:({ color: _vm.textColor || 'white' })},[_vm._l((Object.keys(_vm.mappedPois['Einkaufen'])),function(key){return _c('div',{key:key,staticClass:"card_content__wrapper"},[_c('span',{staticClass:"card_content__key"},[_vm._v(_vm._s(key))]),(_vm.mappedPois['Einkaufen'][key].open)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" "+_vm._s(_vm.mappedPois["Einkaufen"][key].open)+"/ ")]):_vm._e(),(_vm.mappedPois['Einkaufen'][key].open === 0)?_c('span',{staticClass:"card_content__count count_open"},[_vm._v(" 0/ ")]):_vm._e(),(_vm.mappedPois['Einkaufen'][key].all)?_c('span',{staticClass:"card_content__count count_all"},[_vm._v(" "+_vm._s(_vm.mappedPois["Einkaufen"][key].all)+" ")]):_vm._e()])}),_c('div',{staticClass:"w-100 justify-center d-flex pt-5"},[_c('v-btn',{staticClass:"bg-white color-main-blue button-opacity",attrs:{"icon":"","elevation":"1","small":""},on:{"click":function($event){return _vm.linkToList('Einkaufen')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],2)],1)],1):_vm._e()],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }