import flatten from "lodash/flatten";
import debounce from "lodash/debounce";
import "@/App.sass";
import "./Summary.sass";
import { getSummary } from "@/helpers/apiHelper";
import { getSettings } from "@/helpers/commonHelper";
import ColorsMixin from "@/mixins/ColorsMixin.vue";
import "@/lib/colors";
import { apiString } from "@/helpers/detailViewHelper";

const sortObject = o =>
  Object.keys(o)
    .sort()
    .reduce((r, k) => ((r[k] = o[k]), r), {});

export default {
  props: {
    id: {
      type: String,
      default: "eopoi-summary"
    },
    apiKey: String
  },
  mixins: [ColorsMixin],
  data: () => ({
    pois: [],
    mappedPois: {},
    forceSetSearchValue: false,
    settings: {},
    notOpenSum: 0,
    isLoading: true
  }),
  async mounted() {
    this.$store.commit("SET_SETTINGS", { apiKey: this.apiKey });
    await this.$store.dispatch("getMappingFields");
    if (this.initialSettings) {
      this.settings = this.initialSettings;
    } else {
      await this.getSettings();
    }

    // store default values in store
    this.$store.commit("SET_SETTINGS", {
      sort: this.settings.apiKey?.defaultSorting,
      direction: this.settings.apiKey?.defaultDirection,
      isWinter: this.currentSeason === "winter"
    });
    await this.getAndMapPois();
    this.isLoading = false;
    const tours = Object.keys(this.mappedPois["Touren & Wege"] || []);
    tours.forEach(key => {
      this.notOpenSum += this.mappedPois["Touren & Wege"][key].notOpen;
    });
  },
  computed: {
    mappingFields() {
      const mappingFields = this.checkSeason("summer")
        ? this.$store.state.mappingFields.filter(field => field.summer)
        : this.$store.state.mappingFields.filter(field => field.winter);

      if (this.apiKeyObj?.includedMappingFields?.length) {
        return mappingFields.filter(mappingField =>
          this.apiKeyObj.includedMappingFields.includes(mappingField._id)
        );
      } else return mappingFields;
    },
    categoryTypes() {
      return this.$store.state.categoryTypes;
    },
    categoryGroups() {
      return this.$store.state.categoryGroups;
    },
    categories() {
      return flatten(this.categoryGroups?.map(ct => ct.categories));
    },
    selectedCategory: {
      get() {
        return this.$store.state.selectedCategories;
      },
      async set(values) {
        this.$store.commit("SET_SELECTED_CATEGORIES", values);
        await this.getAndMapPois();
      }
    },
    searchValue: {
      get() {
        return this.$store.state.s;
      },
      set: debounce(async function(value) {
        if (
          value?.length === 0 ||
          value?.length >= 3 ||
          this.forceSetSearchValue
        ) {
          this.$store.commit("SET_SEARCH", value);
          await this.getAndMapPois();
          this.forceSetSearchValue = false;
        }
      }, 500)
    },
    apiKeyObj() {
      return this.settings?.apiKey ?? {};
    },

    apiUrl() {
      return this.$store.state.apiUrl;
    },
    colorPrimary() {
      return (
        this.$vuetify?.theme?.currentTheme?.summaryPrimary ?? this.$mainBlue
      );
    },
    colorSecondary() {
      return (
        this.$vuetify?.theme?.currentTheme?.summarySecondary ??
        this.$mainLightBlue
      );
    },

    cardGradientStyle() {
      const primary = this.colorPrimary;
      const accent = this.colorSecondary;
      return {
        "--background": `transparent linear-gradient(180deg, ${accent} 0, ${primary} 100%) 0 0 no-repeat padding-box`,
        "--background-hover": `transparent linear-gradient(180deg, ${accent} 0, ${primary} 80%) 0 0 no-repeat padding-box`
      };
    },

    currentSeason() {
      if (!this.apiKeyObj?.season)
        return this.$store.state.isWinter ? "winter" : "summer";
      if (this.apiKeyObj.season === "principalSeason") {
        return this.settings.currentSeason;
      }
      return this.apiKeyObj.season;
    },
    apiString
  },
  methods: {
    linkToList(mappingField) {
      const mappingFieldObj = this.mappingFields.find(
        mField => mField.title === mappingField
      );
      if (mappingFieldObj._id)
        this.$store.commit("SET_SELECTED_MAPPING_FIELD", mappingFieldObj._id);
      else
        this.$store.commit(
          "SET_SELECTED_MAPPING_FIELD",
          this.mappingFields[0]._id
        );
      window.location.assign(this.$store.state.listUri + this.apiString);
    },
    setOpenClosedAllForField(pois, mappingField, category, poi) {
      if (!this.mappedPois[mappingField]) {
        this.mappedPois[mappingField] = {
          [category]: {
            open: 0,
            all: 0,
            notOpen: 0
          }
        };
      }
      if (!this.mappedPois?.[mappingField]?.[category]) {
        this.mappedPois[mappingField][category] = {
          open: 0,
          all: 0,
          notOpen: 0
        };
      }
      pois[mappingField][category].all += 1;

      if (
        poi?.state?.state === "open" ||
        (poi?.state?.state === "openingHours" && poi?.isOpen)
      )
        pois[mappingField][category].open += 1;

      if (
        (poi?.state?.state !== "" && poi?.state?.state !== "open") ||
        (poi?.state?.state === "openingHours" && !poi?.isOpen)
      )
        pois[mappingField][category].notOpen += 1;
    },
    async getAndMapPois() {
      try {
        this.mappedPois = {};
        if (!this.categoryTypes?.length) {
          await this.$store.dispatch("getCategoryTypes");
        }
        const data = await getSummary(
          this.apiUrl,
          this.apiKey,
          this.createQuery()
        );
        this.pois = data?.data ?? [];
        this.pois.forEach(poi => {
          let categories = poi.categoryObj;
          if (poi.additionalCategoryObj?.length)
            poi.additionalCategoryObj.forEach(additionalCat =>
              categories.push(additionalCat)
            );
          categories.forEach(cat => {
            cat.mappingField = cat?.mappingField?.map(catMField =>
              this.mappingFields.find(mField => mField._id === catMField)
            );
          });

          categories.forEach(category => {
            category.mappingField?.forEach(mappingField => {
              if (mappingField) {
                if (mappingField.title.includes("Freizeit & Aktivität")) {
                  this.setOpenClosedAllForField(
                    this.mappedPois,
                    "Freizeit & Aktivität",
                    "Einrichtungen",
                    poi
                  );
                } else if (mappingField.title.includes("Langlaufen")) {
                  if (
                    poi.state.trackType === "classic" ||
                    poi.state.trackType === "classicAndSkating"
                  ) {
                    this.setOpenClosedAllForField(
                      this.mappedPois,
                      "Langlaufen",
                      "Klassisch",
                      poi
                    );
                  }
                  if (
                    poi.state.trackType === "skating" ||
                    poi.state.trackType === "classicAndSkating"
                  ) {
                    this.setOpenClosedAllForField(
                      this.mappedPois,
                      "Langlaufen",
                      "Skating",
                      poi
                    );
                  }
                } else {
                  const categoryTitle =
                    category.parentCategoryObj?.title ?? category.title;
                  this.setOpenClosedAllForField(
                    this.mappedPois,
                    mappingField.title,
                    categoryTitle,
                    poi
                  );
                }
              }
            });
          });
        });

        this.$forceUpdate();
      } catch (error) {
        console.error(error);
      }
      for (let key in this.mappedPois) {
        this.mappedPois[key] = sortObject(this.mappedPois[key]);
      }
    },
    createQuery() {
      // define query
      const query = {
        extended: true
      };

      // filter by favorites?
      if (this.$store.state.filterFavorites) {
        if (this.$store.state.favorites) {
          query._id = this.$store.state.favorites;
        }
      } else {
        // general filters - only if no favorites are filtered
        query.active = this.apiKeyObj.showReleased;
        query.hidden = 0;
        query.highlight = this.apiKeyObj.showHighlight;

        // normal search
        query.feature_ids = this.$store.state.selectedFeatures
          ? this.$store.state.selectedFeatures.slice()
          : []; // clone array
        query.category_ids = this.$store.state.selectedCategories
          ? this.$store.state.selectedCategories.slice()
          : []; // clone array
        query.cities = this.$store.state.selectedCities
          ? this.$store.state.selectedCities.slice()
          : []; // clone array

        // search term
        if (this.$store.state.s) query.s = this.$store.state.s;

        // manually contain category ids, if needed
        if (
          this.apiKeyObj.containByCategoryGroupIds &&
          this.apiKeyObj.containByCategoryGroupIds.length
        ) {
          query.containByCategoryGroupIds = this.apiKeyObj.containByCategoryGroupIds;
        }
        if (
          this.apiKeyObj.containByCategoryTypeIds &&
          this.apiKeyObj.containByCategoryTypeIds.length
        ) {
          query.containByCategoryTypeIds = this.apiKeyObj.containByCategoryTypeIds;
        }
        if (
          this.apiKeyObj.containByCategoryIds &&
          this.apiKeyObj.containByCategoryIds.length
        ) {
          query.containByCategoryIds = this.apiKeyObj.containByCategoryIds;
        }
        if (this.apiKeyObj.cities?.length) {
          query.cities = this.apiKeyObj.cities;
        }

        // manually contain features
        if (
          this.apiKeyObj.containByFeatureIds &&
          this.apiKeyObj.containByFeatureIds.length
        ) {
          query.containByFeatureIds = this.apiKeyObj.containByFeatureIds;
        }
      }

      return query;
    },
    setFilter() {
      this.forceSetSearchValue = true;
      // eslint-disable-next-line no-self-assign
      this.searchValue = this.searchValue;
    },
    checkSeason(season) {
      if (
        !this.apiKeyObj.season ||
        this.apiKeyObj.season === "principalSeason"
      ) {
        return this.settings?.currentSeason === season;
      } else return this.apiKeyObj.season === season;
    },
    async getSettings() {
      await getSettings.bind(this).call();
    },
    checkMappingField(mappingField) {
      const includedMappingFields = this.apiKeyObj?.includedMappingFields.map(
        field =>
          this.mappingFields.find(mappingField => mappingField._id === field)
      );
      return (
        !includedMappingFields?.length ||
        includedMappingFields.find(mField =>
          mField?.title?.includes(mappingField)
        )
      );
    }
  }
};
